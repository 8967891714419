import { ComponentGlobalSectionWithText } from 'types/cms';
import {
  Container,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Center,
  Flex,
  Group,
  Stack,
} from '@mantine/core';
import React, { Fragment } from 'react';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { homeStyles } from '../../modules/shared/styles';
import Link from 'next/link';
import EmbedContent from '@/components/Blocks/EmbedContent';

const SectionWithText = ({ section }: ComponentGlobalSectionWithText) => {
  const { classes } = homeStyles({});
  const convertColourToThemeColour = (colour?: string | null): string => {
    if (!colour) return '';
    if (colour === 'red') return 'red.9';
    if (colour === 'black') return 'black.9';
    return colour;
  };

  return (
    <Container fluid px={0}>
      {section?.map((item) => (
        <Container
          sx={(theme) => ({
            [theme.fn.largerThan('sm')]: {
              borderBottom: item?.bottomBorder ? `10px solid ${theme.colors.red[9]}` : '',
            },
          })}
          key={item?.id}
          fluid
          bg={convertColourToThemeColour(item?.bgColour)}
          px={0}
        >
          <Container px={8} maw={1200}>
            <Grid my={0}>
              {item?.videoLink ? (
                <Grid.Col
                  // style={{ alignSelf: 'center' }}
                  p={0}
                  md={6}
                  orderMd={item?.showOnRight ? 2 : 1}
                >
                  <EmbedContent link={item?.videoLink} noContainer />
                </Grid.Col>
              ) : (
                getStrapiImage(item?.image?.data?.attributes!, ImageSizeNameEnum.large)?.url && (
                  <Grid.Col
                    style={{ alignSelf: 'center' }}
                    p={0}
                    md={6}
                    orderMd={item?.showOnRight ? 2 : 1}
                  >
                    <Image
                      src={
                        getStrapiImage(item?.image?.data?.attributes!, ImageSizeNameEnum.large)?.url
                      }
                    />
                  </Grid.Col>
                )
              )}
              <Grid.Col
                sx={(theme) => ({
                  [theme.fn.largerThan('sm')]: {
                    borderLeft: item?.dividerBorder ? `5px solid ${theme.colors.yellow[6]}` : '',
                  },
                })}
                py={0}
                md={item?.image?.data || item?.videoLink ? 6 : 12}
                orderMd={item?.showOnRight ? 1 : 2}
              >
                <Container
                  className={item?.image?.data ? classes.button : classes.buttonWithoutImage}
                  mb="0"
                  p="xl"
                >
                  <Title
                    sx={{
                      '& > :first-of-type > :first-of-type': {
                        marginTop: 0,
                      },
                    }}
                    mt={0}
                    order={3}
                    fw={400}
                    lh={1.5}
                  >
                    <div dangerouslySetInnerHTML={{ __html: item?.text || '' }} />
                  </Title>
                  {item?.btnUrl && (
                    <Stack
                      className={item.image?.data ? classes.button : classes.buttonWithoutImage}
                    >
                      <Link passHref legacyBehavior href={{ pathname: item?.btnUrl }}>
                        <Button
                          component="a"
                          color={item.bgColour !== 'yellow' ? 'yellow' : 'red.9'}
                          size="xl"
                          mt="xl"
                          radius="md"
                        >
                          <Text c={'white'} size="xl">
                            {item?.btnText}
                          </Text>
                        </Button>
                      </Link>
                    </Stack>
                  )}
                </Container>
              </Grid.Col>
            </Grid>
          </Container>
        </Container>
      ))}
    </Container>
  );
};

export default SectionWithText;
