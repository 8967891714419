import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { getStripe } from '@/utils/helpers';
import { StripeElementsOptionsMode, StripeError } from '@stripe/stripe-js';
import CheckoutPaymentDetails from './CheckoutPaymentDetails';
import { UseFormReturnType } from '@mantine/form';
import { CheckoutModalType } from './CheckoutModalType';

const CheckoutForm = ({
  form,
  close,
  nextStep,
  prevStep,
}: {
  form: UseFormReturnType<CheckoutModalType>;
  close: () => void;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const stripePromise = getStripe();
  const options: StripeElementsOptionsMode = {
    mode: 'subscription',
    currency: 'gbp',
    amount: 0,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutPaymentDetails form={form} close={close} prevStep={prevStep} nextStep={nextStep} />
    </Elements>
  );
};

export default CheckoutForm;
