import { ComponentGlobalImages } from 'types/cms';
import { Box, Container, Flex, Grid, Image, Text } from '@mantine/core';
import React from 'react';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { homeStyles } from '../../modules/shared/styles';

const ImageList = ({ imageList, bg }: ComponentGlobalImages) => {
  const { classes } = homeStyles({});
  const convertColourToThemeColour = (colour?: string | null): string => {
    if (!colour) return '';
    if (colour === 'red') return 'red.9';
    if (colour === 'black') return 'black.9';
    return colour;
  };
  const backgroundColor = convertColourToThemeColour(bg);
  const isWhiteBackground = backgroundColor === '';

  return (
    <Container
      bg={backgroundColor}
      fluid
      px={0}
      sx={{
        color: isWhiteBackground ? 'black' : 'white',
      }}
    >
      <Container maw={1200} px={0}>
        <Flex
          mih={150}
          gap="xl"
          justify="center"
          align="center"
          py="xl"
          direction="row"
          wrap="wrap"
          sx={(theme) => ({
            [theme.fn.largerThan('md')]: {
              justifyContent: 'space-between',
            },
          })}
        >
          {imageList?.map((item) => (
            <Box key={item?.id} sx={{ textAlign: 'center' }}>
              <Image
                height={100}
                width={130}
                src={getStrapiImage(item?.image?.data?.attributes!, ImageSizeNameEnum.large)?.url}
              />
              <Text align="center" size="xl" fw={500} mt="sm">
                {item?.text}
              </Text>
            </Box>
          ))}
        </Flex>
      </Container>
    </Container>
  );
};

export default ImageList;
