import React, { useState } from 'react';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Button, Group, Title } from '@mantine/core';
import { StripeError } from '@stripe/stripe-js';
import { useAppSelector } from '@/redux/hooks';
import { StripeDonation } from '@/redux/slices/types';
import { UseFormReturnType } from '@mantine/form';
import { CheckoutModalType } from './CheckoutModalType';
import { useSession } from 'next-auth/react';

function CheckoutPaymentDetails({
  form,
  close,
  nextStep,
  prevStep,
}: {
  form: UseFormReturnType<CheckoutModalType>;
  close: () => void;
  nextStep: () => void;
  prevStep: () => void;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const { data } = useSession();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const { donations } = useAppSelector((state) => state.donation);
  const userState = useAppSelector((state) => state.user);
  const subscriptionItem = donations.filter((donation: StripeDonation) => donation.startDate)[0];

  const handleError = (error: StripeError) => {
    setLoading(false);
    setErrorMessage(error.message!);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const addressElement = elements?.getElement('address');
    const address = await addressElement?.getValue();
    if (!address?.complete) {
      setErrorMessage('address incomplete');
      return;
    }
    setErrorMessage('');
    setLoading(true);
    // const target = e.target as typeof e.target & CheckoutFormType;

    const customerInformation = {
      email: form.values.email,
      firstName: address?.value.firstName,
      lastName: address?.value.lastName,
      telephone: address?.value.phone,
      address1: address?.value.address.line1,
      address2: address?.value.address.line2,
      address3: address?.value.address.city,
      ukpostcode: address?.value.address.postal_code,
      country: address?.value.address.country,
    };

    if (!stripe) {
      return;
    }
    setLoading(true);
    if (!elements) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the SetupIntent and obtain clientSecret
    const res = await fetch('/api/create-setup-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscriptionItem,
        customerInformation,
        userState,
        metadata: {
          keepInTouch: JSON.stringify(form.values.keepInTouch),
          giftaid: form.values.giftaid,
        },
      }),
    });

    const { type, clientSecret, subscriptionId } = await res.json();
    const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error } = await confirmIntent({
      // redirect: 'if_required',
      elements,
      clientSecret,
      confirmParams: {
        return_url:
          process.env.NODE_ENV === 'development'
            ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/checkout/thank-you?subId=${subscriptionId}`
            : `https://${window.location.hostname}/checkout/thank-you?subId=${subscriptionId}`,
      },
    });

    if (error) {
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`.
    }
  };

  return (
    <>
      <Title order={3}>Billing Details</Title>
      <AddressElement
        options={{
          defaultValues: {
            firstName: data?.token.FirstName,
            lastName: data?.token.LastName,
            phone: data?.token.phone?.toString(),
            address: {
              line1: data?.token.address1,
              line2: data?.token.address2,
              city: data?.token.city,
              postal_code: data?.token.postcode,
              country: 'GB',
            },
          },
          display: { name: 'split' },
          fields: { phone: 'always' },
          mode: 'billing',
        }}
      />
      <Title order={3}>Card Details</Title>
      <PaymentElement
        onLoadError={(error) => {
          setErrorMessage(
            'Sorry this browser/app is not supported to take Stripe payments. Please try a new tab or browser.'
          );

          throw new Error(`Stripe didnt load ${error}`);
        }}
      />
      {errorMessage && (
        <Alert mt={5} color="red">
          {errorMessage}
        </Alert>
      )}

      <Group position="apart" mt="xl">
        <Button variant="default" onClick={() => close()}>
          Cancel
        </Button>
        <Button.Group>
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button onClick={handleSubmit} loading={loading}>
            CONFIRM
          </Button>
        </Button.Group>
      </Group>
    </>
  );
}

export default CheckoutPaymentDetails;
