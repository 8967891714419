import React, { useState } from 'react';
import {
  Paper,
  Divider,
  Button,
  Container,
  Text,
  Alert,
  TextInput,
  PasswordInput,
  Group,
} from '@mantine/core';
import Link from 'next/link';
import { CheckoutModalType } from './CheckoutModalType';
import { UseFormReturnType } from '@mantine/form';
import { FiAlertCircle } from 'react-icons/fi';

const CheckoutAccount = ({
  form,
  errorMsg,
  goToGuestStep,
}: {
  form: UseFormReturnType<CheckoutModalType>;
  errorMsg: string;
  goToGuestStep: () => void;
}) => {
  return (
    <Container>
      <Paper radius="md">
        <Button onClick={() => goToGuestStep()} component="a" fullWidth type="submit">
          Guest Checkout
        </Button>
        <Text align="center" mt={10} fw={600} size="xs">
          You can create an account after Guest Checkout
        </Text>
      </Paper>
      <Paper radius="md">
        <Divider my="xs" label="OR sign in if you have an account" labelPosition="center" />
        <Container size={500} px={0} mb={20}>
          {errorMsg && (
            <Alert icon={<FiAlertCircle size={16} />} title="Sign in failed!" color="red">
              {errorMsg}
            </Alert>
          )}
          <TextInput
            label="Email"
            placeholder="you@gmail.com"
            required
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group position="apart" mt="md">
            {/* <Checkbox label="Stay signed in" /> */}
            <Link href="/account/reset-password">Forgot password?</Link>
          </Group>
        </Container>
      </Paper>
    </Container>
  );
};

export default CheckoutAccount;
