import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper2: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: 200,
    display: 'flex',
    [theme.fn.largerThan('sm')]: {
      display: 'flex',

      minHeight: 500,
      maxWidth: '1200px',
      margin: '0 auto',
    },
    img: {
      objectFit: 'cover',
    },
  },

  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    [theme.fn.largerThan('sm')]: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 500,
      maxWidth: '1200px',
      // padding: '80px 0 80px',
      margin: '0 auto',
    },
    img: {
      objectFit: 'cover',
    },
  },
  content2: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.lg,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    position: 'relative',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.lg,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    position: 'relative',
    width: '90%',
    maxWidth: '1110px',
    margin: '25px auto',
  },
  cItem2: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cItem: {
    flex: `0 0 70%`,

    [theme.fn.smallerThan('sm')]: {
      flex: `0 0 69%`,
    },
  },

  hideOnMobile: {
    display: 'block',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  title: {
    lineHeight: 1.4,
    // padding: '8px 0',
    marginTop: '10px auto',
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.xl * 2.5,
    fontWeight: 900,
    span: {
      // backgroundColor: theme.white,
      // outline: `7px solid ${theme.colors.yellow[6]}`,
      color: theme.colors.yellow[6],
      display: 'inline',
      // padding: '0.5rem',
      boxDecorationBreak: 'clone',
    },
    [theme.fn.smallerThan('sm')]: {
      fontSize: 22,
      padding: '0',
      margin: '0 auto',
      span: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}));
