import Embed from 'react-embed';
import { Box, Container } from '@mantine/core';
import React, { useEffect, useState } from 'react';

export default function EmbedContent({
  link,
  noContainer,
}: {
  link: string | null | undefined;
  noContainer?: boolean;
}) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!link) {
    return null;
  }
  const embedComponent = (
    <Box
      sx={(theme) => ({
        [theme.fn.largerThan('sm')]: {
          border: '1px solid #ccc', // Add your border styles here
          borderRadius: theme.radius.md,
          overflow: 'hidden',
        },
      })}
    >
      <Embed url={link} />
    </Box>
  );

  if (noContainer) {
    return isClient ? embedComponent : null;
  }

  return <Container my={20}>{isClient && embedComponent}</Container>;
}
