import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Group, Modal, Stepper, Text, StepperProps } from '@mantine/core';
import {
  useForm,
  isEmail,
  isNotEmpty,
  isInRange,
  hasLength,
  UseFormReturnType,
} from '@mantine/form';
import CheckoutAccount from './CheckoutAccount';
import { CheckoutModalType } from './CheckoutModalType';
import { useSession, signIn } from 'next-auth/react';
import { showNotification } from '@mantine/notifications';
import CheckoutGuest from './CheckoutGuest';
import CheckoutStyledStepper from './CheckoutStyledStepper';
import CheckoutPreferences from './CheckoutPreferences';
import CheckoutForm from './CheckoutForm';

type CheckoutModalProps = {
  opened: boolean;
  close: () => void;
};

const CheckoutModal = ({ opened, close }: CheckoutModalProps) => {
  const [errorMsg, setErrorMsg] = useState('');
  const { data, status } = useSession();
  const [active, setActive] = useState(0);
  useEffect(() => {
    status === 'authenticated' && setActive(2);
  }, [status]);

  const form = useForm<CheckoutModalType>({
    validateInputOnChange: ['email'],
    initialValues: {
      guestCheckout: false,
      email: '',
      password: '',
      giftaid: false,
      keepInTouch: '',
    },
    validate: {
      email: (value: string) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email'),
      password: hasLength({ min: 2 }, 'Password must be at least 2 characters long'),
    },
    // validate: {
    //   quantity: (value) => active === 0 && isInMaxTicketRange(value),
    //   attendees: {},
    // },
  });

  const validateEmailAndNext = () => {
    form.validateField('email');
    if (form.isValid('email')) {
      nextStep();
    }
  };
  const guestCheckout = () => {
    setActive(1);
  };
  const logIn = async () => {
    form.validateField('email');
    form.validateField('password');
    if (!form.isValid('email') || !form.isValid('password')) {
      return;
    }
    setErrorMsg('');
    signIn('credentials', {
      email: form.values.email,
      password: form.values.password,
      redirect: false,
    }).then((res) => {
      if (res && res.ok) {
        showNotification({
          title: 'Successful',
          message: 'You have been logged in!',
          autoClose: 5000,
          color: 'green',
        });
        setActive(2);
      }
      if (res && !res.ok) {
        setErrorMsg(res.error!);
        showNotification({
          message: res.error,
          color: 'red',
        });
      }
    });
  };
  const nextStep = (): number => {
    setActive((current) => {
      //   if (form.validate().hasErrors) {
      //     return current;
      //   }
      return current < 4 ? current + 1 : current;
    });
    return active;
  };

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  return (
    <Modal padding={'xs'} opened={opened} onClose={close}>
      <Container>
        <CheckoutStyledStepper size="xs" active={active} breakpoint="md" orientation="horizontal">
          {/* Step 0 */}
          <Stepper.Step>
            <CheckoutAccount goToGuestStep={guestCheckout} form={form} errorMsg={errorMsg} />
          </Stepper.Step>

          {/* Step 1 EMAIL */}
          <Stepper.Step>
            <CheckoutGuest form={form} />
          </Stepper.Step>

          {/* Step 2 GIFTAID and feedback*/}
          <Stepper.Step>
            <CheckoutPreferences form={form} />
          </Stepper.Step>

          {/* Step 3 */}
          <Stepper.Step>
            <CheckoutForm form={form} prevStep={prevStep} close={close} nextStep={nextStep} />
          </Stepper.Step>

          <Stepper.Completed>
            <Container>
              <Grid>
                <Grid.Col span={9}>Thank you</Grid.Col>
              </Grid>
            </Container>
          </Stepper.Completed>
        </CheckoutStyledStepper>
        {active !== 3 && (
          <Group position="apart" mt="xl">
            <Button variant="default" onClick={() => close()}>
              Cancel
            </Button>
            <Button.Group>
              {active >= 1 && status !== 'authenticated' && (
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
              )}
              {active === 0 && <Button onClick={() => logIn()}>Sign In</Button>}
              {active === 1 && <Button onClick={() => validateEmailAndNext()}>Next</Button>}
              {active === 2 && <Button onClick={() => nextStep()}>Next</Button>}
              {/* {active === 3 && <Button onClick={() => nextStep()}>Next</Button>} */}
            </Button.Group>
          </Group>
        )}
      </Container>
    </Modal>
  );
};

export default CheckoutModal;
