import { useMediaQuery } from '@mantine/hooks';
import { UploadFile } from 'types/cms';

export enum ImageSizeNameEnum {
  thumbnail = 'thumbnail',
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export const getStrapiImage = (file: UploadFile, fixedSize?: ImageSizeNameEnum) => {
  const matches = useMediaQuery('(min-width: 900px)', false, { getInitialValueInEffect: false });
  const responsiveSize = matches ? ImageSizeNameEnum.large : ImageSizeNameEnum.xsmall;
  const size = !fixedSize ? responsiveSize : fixedSize;

  if (!file) {
    return null;
  }
  if (!file.mime) {
    return file;
  }
  const isImage = file.mime.includes('image');
  if (!isImage) {
    return null;
  }

  const { formats } = file;
  let foundUrl = null;

  if (
    size === ImageSizeNameEnum.large &&
    formats[size] &&
    file.size < formats[size].size &&
    file.width != null &&
    file.width > 992
  ) {
    return file;
  }

  if (size in formats) {
    return formats[size];
  }

  //couldnt find the size requested, getting next size down
  const sizes = ['thumbnail', 'xsmall', 'small', 'medium', 'large'];

  const givenSizeIndex = sizes.findIndex((el) => el === size); //3

  // there is no (medium,xsmall,small or large), try get smaller size
  if (!foundUrl) {
    for (let index = 1; index < givenSizeIndex; index += 1) {
      const exists = formats[sizes[givenSizeIndex - index]];
      if (exists) {
        foundUrl = exists;
        break;
      }
    }
  }

  if (!foundUrl) {
    foundUrl = file;
  }
  return foundUrl;
};
