import { Container, Title, SimpleGrid, Anchor, Box } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import {
  AppealEntity,
  ChallengeEntity,
  ComponentChallengesFeaturedEventsChallenges,
} from 'types/cms';
import Card from '../Cards';
import Carousel from '../Carousel';
import { homeStyles } from '../../modules/shared/styles';

const FeaturedEvents: React.FC<ComponentChallengesFeaturedEventsChallenges> = ({
  title,
  footerLinkHref,
  footerLinkText,
  challenges,
}: ComponentChallengesFeaturedEventsChallenges) => {
  const carouselData = challenges?.data.map((challenge: ChallengeEntity) => {
    return {
      title: challenge.attributes?.title,
      description: challenge.attributes?.shortDescription,
      image: challenge.attributes?.image,
      btnText: 'Learn more',
      btnUrl: `/challenge/${challenge.attributes?.slug}`,
    };
  });
  const { classes } = homeStyles({});

  return (
    <Container className={classes.sectionBackground} size="lg" mt={10}>
      <Box my="3rem">
        <Title order={3} mb="1rem" className={classes.heading}>
          {title}
        </Title>

        <Carousel data={carouselData} view={<Card />} />
        <Link passHref href={{ pathname: footerLinkHref }} className={classes.viewAll}>
          <Anchor component="a">{footerLinkText}</Anchor>
        </Link>
      </Box>
    </Container>
  );
};

export default FeaturedEvents;
