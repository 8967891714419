import { Container, Title, SimpleGrid, Text, Box, Paper } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import { ComponentGlobalSectionHeading } from 'types/cms';
import Card from '../Cards';
import Carousel from '../Carousel';
import { homeStyles } from '../../modules/shared/styles';

const SectionWithCards: React.FC<ComponentGlobalSectionHeading> = ({
  highlightBackground,
  title,
  showFooter,
  footerLinkUrl,
  footerLinkText,
  useSlider,
  cardGroup,
  showBorder,
}: ComponentGlobalSectionHeading) => {
  const { classes, theme } = homeStyles({ backgroundColor: highlightBackground });

  return (
    <Container pt="xl" pb={26} fluid className={classes.sectionBackground}>
      <Container>
        <Title mb="1rem" ta="center">
          {title}
        </Title>

        {useSlider ? (
          <Carousel data={cardGroup} view={<Card showBorder={Boolean(showBorder)} />} />
        ) : (
          <SimpleGrid
            breakpoints={[
              { maxWidth: 980, cols: 3, spacing: 'md' },
              { maxWidth: 755, cols: 2, spacing: 'sm' },
              { maxWidth: 600, cols: 1, spacing: 'sm' },
            ]}
            cols={3}
          >
            {cardGroup?.map((row) => (
              <Card key={row?.title} {...row} />
            ))}
          </SimpleGrid>
        )}
        {showFooter && (
          <Box mt={15}>
            <Link passHref href={{ pathname: footerLinkUrl }}>
              <Text align="center">{footerLinkText}</Text>
            </Link>
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default SectionWithCards;
