import { Box, Button, Container, Grid, MediaQuery, Text, Title, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { MdAlternateEmail } from 'react-icons/md';
import Image from 'next/image';
import { useForm } from '@mantine/form';
import fetch from 'isomorphic-unfetch';
import { ComponentGlobalSubscribe } from 'types/cms';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { useStyles } from './styles';
import { imageMaxWidth } from '../shared/styles';

interface subscribeCtaType extends Omit<ComponentGlobalSubscribe, 'id'> {}

const SubscribeCta: React.FC<subscribeCtaType> = ({ title, btnText, description, image }) => {
  const { classes } = useStyles();
  const [message, setMessage] = useState('');
  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = (values: { email: string }) => {
    fetch('/api/subscribe-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: values.email }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 400) {
          throw new Error(responseJson.message);
        }
        setMessage('Thank you for subscribing!');
      })
      .catch((error) => {
        const err = JSON.parse(error.message);
        setMessage(err.message);
      });
  };

  return (
    <Box mb="3rem" className={classes.section}>
      <Container size="lg">
        <Grid align="center">
          <Grid.Col xs={12} sm={6} order={1}>
            <Title order={1}>{title}</Title>
            <Text size="sm" color="dimmed" mb={24}>
              {description}
            </Text>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <TextInput
                withAsterisk
                label="Email"
                icon={<MdAlternateEmail />}
                placeholder="Your email"
                mb={12}
                {...form.getInputProps('email')}
              />
              <Text size="sm" color="dimmed" my={20}>
                {message}
              </Text>
              <Button type="submit" fullWidth color="dark">
                {btnText}
              </Button>
            </form>
          </Grid.Col>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Grid.Col xs={12} sm={6}>
              <Image
                src={getStrapiImage(image?.data?.attributes!, ImageSizeNameEnum.small)?.url}
                style={imageMaxWidth}
                alt="hello"
                width="500"
                height="100"
              />
            </Grid.Col>
          </MediaQuery>
        </Grid>
      </Container>
    </Box>
  );
};

export default SubscribeCta;
