import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  Button,
  Group,
  Grid,
  Paper,
  Select,
  NumberInput,
  ActionIcon,
  NumberInputHandlers,
  SimpleGrid,
  TextInput,
} from '@mantine/core';
import {
  ComponentDonationQuickDonateOptions,
  Enum_Componentdonationquickdonateoptions_Frequency,
  Enum_Componentdonationquickdonateoptions_Type,
} from 'types/cms';
import {
  filterBySingle,
  filterByRegular,
  filterByFreqName,
  generateId,
  getRegularOptionList,
  getDisplayTextFreqList,
  displayNameForFrequency,
  filterByOtherAmount,
} from '../../utils/helpers';
import { useAppDispatch } from '../../redux/hooks';
import { addDonation, setIsSidebarOpen } from '../../redux/slices/donationSlice';
import { useStyles } from './styles';
import { EnumIntention, StripeDonation } from '../../redux/slices/types';
import { useDisclosure } from '@mantine/hooks';
import CheckoutModal from '../CheckoutModal';
import { fbEvent } from '../Facebook/conversion-api';

export type DonateBoxProps = {
  donateOptions: ComponentDonationQuickDonateOptions[];
  sfId?: string;
  zakat?: boolean;
  sadakah?: boolean;
  sadakahJariya?: boolean;
  pageInfo?: any;
  appealId?: string | undefined;
  donateDescription?: string;
  // sfUpsellProjectId?: string;
};
const DonateBox: React.FC<DonateBoxProps> = ({
  donateOptions,
  pageInfo,
  appealId,
  donateDescription,
}) => {
  const [maxQty, setMaxQty] = useState(10);
  const handlers = useRef<NumberInputHandlers>();
  const { sfId, sadakah, zakat, sadakahJariya } = pageInfo;
  const dispatch = useAppDispatch();
  const [donateOptionsView, setDonateOptionsView] = useState<ComponentDonationQuickDonateOptions[]>(
    donateOptions!
  );
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const showIntentions = zakat || sadakah || sadakahJariya;
  const intentionList = [];
  sadakah && intentionList.push({ value: EnumIntention.Sadaqa, label: EnumIntention.Sadaqa });
  zakat && intentionList.push({ value: EnumIntention.Zakat, label: EnumIntention.Zakat });
  sadakahJariya &&
    intentionList.push({ value: EnumIntention.SadaqaJariya, label: EnumIntention.SadaqaJariya });

  // const homePageTitle = pageInfo.title === 'index' ? 'Most Needed' : pageInfo.title;
  const projectName = donateDescription || pageInfo.title;

  const singleDonateOptions = filterBySingle(donateOptions!);
  const regularDonateOptions = filterByRegular(donateOptions!);
  const regularFreqList = getRegularOptionList(regularDonateOptions);
  const isOnlySingle = singleDonateOptions.length >= 1 && regularDonateOptions.length === 0;
  const isOnlyRegular = singleDonateOptions.length === 0 && regularDonateOptions.length >= 1;
  const isMix = singleDonateOptions.length >= 1 && regularDonateOptions.length >= 1;
  const onlyOneRegularOptionAvailable = regularFreqList.length === 1;
  const regularFreqSelectList = getDisplayTextFreqList(regularFreqList);
  const firstValue = regularFreqSelectList[0]?.value;
  const [freq, setFreq] = useState<string>(firstValue);
  const [intention, setIntention] = useState<string | null>(intentionList[0]?.value || 'Sadaqa');

  const [isRegular, setIsRegular] = useState<Boolean>(false);

  const [appealForm, setAppealForm] = useState<StripeDonation>({
    appealId: appealId!,
    name: projectName,
    sku: sfId! || process.env.NEXT_PUBLIC_DEFAULT_SF_PROJECT_ID!,
    sfProjectId: sfId! || process.env.NEXT_PUBLIC_DEFAULT_SF_PROJECT_ID!,
    sfVariationId: '',
    amount: 0,
    interval: Enum_Componentdonationquickdonateoptions_Frequency.Single,
    id: generateId(),
    quantity: 1,
    intention,
    upsellId: pageInfo.sfUpsellProjectId,
    startDate: null,
    endDate: null,
    deliveryStreet: '',
    deliveryTown: '',
    deliveryPostCode: '',
    qurbaniFat: '',
    qurbaniSize: 'medium',
    qurbaniSpecialRequest: '',
  });

  const handlePaymentRecurring = (value: boolean) => {
    if (value) {
      setIsRegular((v) => value);
      setFreq(() => firstValue);
      setAppealForm((prevState) => {
        return {
          ...prevState,
          interval: firstValue,
        };
      });
      setDonateOptionsView(() => filterByFreqName(donateOptions!, firstValue));
      return;
    }
    setIsRegular((v) => value);
    setFreq(() => Enum_Componentdonationquickdonateoptions_Frequency.Single);
    setAppealForm((prevState) => {
      return {
        ...prevState,
        interval: Enum_Componentdonationquickdonateoptions_Frequency.Single,
      };
    });
    setDonateOptionsView(() => singleDonateOptions);
  };

  const selectInitialOption = () => {
    if (isOnlyRegular) {
      handlePaymentRecurring(true);
      return;
    }
    if (isOnlySingle) {
      handlePaymentRecurring(false);
      return;
    }
    isRegular ? handlePaymentRecurring(true) : handlePaymentRecurring(false);
  };

  useEffect(() => {
    selectInitialOption();
    handlePriceChange(donateOptionsView[0].price!, donateOptionsView[0]);
  }, []);

  const handleFreqSelectChange = (item: Enum_Componentdonationquickdonateoptions_Frequency) => {
    setFreq(() => item);
    setAppealForm((prevState) => {
      return {
        ...prevState,
        interval: item,
      };
    });
    setDonateOptionsView(filterByFreqName(donateOptions!, item));
  };

  const handleIntentionChange = (newVal: string) => {
    setIntention(() => newVal);
    setAppealForm((prevState) => {
      return {
        ...prevState,
        intention: newVal,
      };
    });
  };

  const handleSubmit = () => {
    fbEvent({
      enableStandardPixel: true,
      eventName: 'AddToCart',
      value: appealForm.amount,
      currency: 'GBP',
      products: [
        {
          sku: appealForm.sfProjectId,
          quantity: appealForm.quantity,
        },
      ],
    });
    setAppealForm({ ...appealForm, id: generateId() });
    dispatch(addDonation(appealForm));
    if (appealForm.startDate !== null && appealForm.endDate !== null) {
      open();
    } else {
      dispatch(setIsSidebarOpen(true));
    }
  };

  // const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
  //   setAppealForm({
  //     ...appealForm,
  //     [evt.target.name]: value,
  //   });
  // };

  const handlePriceChange = (value: number, option: ComponentDonationQuickDonateOptions) => {
    const minimumAmount = value < 1 ? 1 : value;
    setAppealForm({
      ...appealForm,
      amount: minimumAmount,
      sfVariationId: option.sfId!,
      interval: option.frequency!,
      intention,
      startDate: option.startDate!,
      endDate: option.endDate!,
      quantity:
        appealForm.quantity! > (option.maxQuantity || 1)
          ? option.maxQuantity || 1
          : appealForm.quantity,
    });
    setMaxQty(option.maxQuantity!);
  };

  return (
    <Paper shadow="md" p="sm" radius="md">
      {opened && <CheckoutModal opened={true} close={close} />}
      {/* {!isOnlySingle && !isOnlyRegular && ( */}
      <Grid grow gutter="sm" mb={10}>
        <Grid.Col xs={12} mb={5}>
          <Group position="apart" grow>
            <Button.Group>
              {(isMix || isOnlySingle) && (
                <Button
                  size="xl"
                  fullWidth
                  color={!isRegular ? 'yellow' : 'gray.2'}
                  onClick={() => handlePaymentRecurring(false)}
                >
                  <Text color="gray.8" size={20} weight={600}>
                    Give Once
                  </Text>
                </Button>
              )}
              {(isMix || isOnlyRegular) && (
                <Button
                  size="xl"
                  fullWidth
                  color={isRegular || isOnlyRegular ? 'yellow' : 'gray.2'}
                  onClick={() => handlePaymentRecurring(true)}
                >
                  <Text color="gray.8" size={20} weight={600}>
                    {onlyOneRegularOptionAvailable
                      ? displayNameForFrequency[regularFreqList[0]!]
                      : 'Regular'}
                  </Text>
                </Button>
              )}
            </Button.Group>
          </Group>
        </Grid.Col>
      </Grid>
      {/* )} */}
      {isRegular && !onlyOneRegularOptionAvailable && (
        <Select
          mb={15}
          value={freq}
          onChange={handleFreqSelectChange}
          data={regularFreqSelectList}
        />
      )}
      <Grid grow gutter="sm" mb={10} columns={12}>
        {donateOptionsView &&
          donateOptionsView
            .filter((el) => el.type !== Enum_Componentdonationquickdonateoptions_Type.Variable)
            .map((options) => (
              <Grid.Col
                span={3}
                key={options?.id}
                onClick={
                  () => handlePriceChange(options?.price || 0, options)
                  // , options?.sfId || '', options?.frequency!)
                }
              >
                <Button
                  size="lg"
                  color={appealForm.amount === options?.price ? 'yellow' : 'gray.2'}
                  fullWidth
                >
                  <Text size="sm" color="gray.7">
                    {options?.displayText}
                  </Text>
                </Button>
              </Grid.Col>
            ))}
        {filterByOtherAmount(donateOptionsView).length === 1 && (
          <Grid.Col span={3}>
            <NumberInput
              placeholder="Other amount"
              precision={2}
              step={0.01}
              min={1}
              variant="unstyled"
              // // TODO: need to make the formatter work
              // parser={(value) => {

              //   return value!.replace(/(\D*)|\£\s?|(,*)/g, '');
              // }}
              // formatter={(value) => {
              //   return !Number.isNaN(parseFloat(value!))
              //     ? `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              //     : 'Other amount ';
              // }}
              onChange={(val: number) =>
                handlePriceChange(
                  val,
                  filterByOtherAmount(donateOptionsView)[0]
                  // filterByOtherAmount(donateOptionsView)[0].sfId!,
                  // filterByOtherAmount(donateOptionsView)[0].frequency!
                )
              }
              className={classes.otherAmount}
              size="md"
            />
          </Grid.Col>
        )}
      </Grid>
      <SimpleGrid spacing="xs" mb={15} cols={2}>
        {maxQty > 1 && (
          <Grid justify="space-evenly" align="center">
            {/* <Grid.Col span={3}> */}
            <ActionIcon size={39} variant="light" onClick={() => handlers.current?.decrement()}>
              –
            </ActionIcon>
            {/* </Grid.Col> */}

            <NumberInput
              hideControls
              // label="Quantity"
              value={appealForm.quantity}
              onChange={(val) => setAppealForm({ ...appealForm, quantity: val! })}
              handlersRef={handlers}
              max={maxQty}
              min={1}
              styles={{ input: { width: 43, textAlign: 'center' } }}
            />
            {/* <Grid.Col span={3}> */}
            <ActionIcon size={39} variant="light" onClick={() => handlers.current?.increment()}>
              +
            </ActionIcon>
            {/* </Grid.Col> */}
          </Grid>
        )}
        {/* </Group> */}
        {showIntentions && (
          <Select
            value={intention}
            onChange={handleIntentionChange}
            // label="Donation Intention"
            placeholder="Pick one"
            data={intentionList}
          />
        )}
      </SimpleGrid>
      {projectName === 'UK Qurbani Home Delivery' && (
        <SimpleGrid spacing="xs" mb={15} cols={2}>
          <TextInput
            value={appealForm.deliveryStreet}
            onChange={(val) =>
              setAppealForm({ ...appealForm, deliveryStreet: val.currentTarget.value })
            }
            name="deliveryStreet"
            label="Door number & Street"
            required
          />
          <TextInput
            value={appealForm.deliveryTown}
            onChange={(val) =>
              setAppealForm({ ...appealForm, deliveryTown: val.currentTarget.value })
            }
            name="deliveryTown"
            label="Town/City"
            required
          />
          <TextInput
            value={appealForm.deliveryPostCode}
            onChange={(val) =>
              setAppealForm({ ...appealForm, deliveryPostCode: val.currentTarget.value })
            }
            name="deliveryPostCode"
            label="Postcode"
            required
          />
          <Select
            value={appealForm.qurbaniFat}
            onChange={(val) => setAppealForm({ ...appealForm, qurbaniFat: val })}
            label="Fat"
            placeholder="Keep Fat?"
            data={[
              {
                value: 'true',
                label: 'Fat on',
              },
              {
                value: 'false',
                label: 'Fat off',
              },
            ]}
          />
          <Select
            value={appealForm.qurbaniSize}
            onChange={(val) => setAppealForm({ ...appealForm, qurbaniSize: val })}
            label="Cut"
            defaultValue="medium"
            placeholder="Cut Size?"
            data={[
              {
                value: 'small',
                label: 'Small cut size',
              },
              {
                value: 'medium',
                label: 'Medium cut size',
              },
              {
                value: 'large',
                label: 'Large cut size',
              },
            ]}
          />
          <TextInput
            value={appealForm.qurbaniSpecialRequest}
            onChange={(val) =>
              setAppealForm({ ...appealForm, qurbaniSpecialRequest: val.currentTarget.value })
            }
            name="qurbaniSpecialRequests"
            label="Special Request"
            placeholder="e.g Keep legs whole, rest to be diced "
          />
        </SimpleGrid>
      )}
      <Button
        disabled={appealForm.amount === 0}
        fullWidth
        size="xl"
        color="red.9"
        onClick={handleSubmit}
      >
        {projectName === 'UK Qurbani Home Delivery' ? 'Order' : 'Donate'}
      </Button>
    </Paper>
  );
};

export default DonateBox;
