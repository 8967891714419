import { createStyles } from '@mantine/core';

export const homeStyles = createStyles((theme) => ({
  heading: {
    textAlign: 'center',
  },
  sectionSpacing: {
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
    [theme.fn.largerThan('sm')]: {
      marginTop: theme.spacing.xl * 2,
      marginBottom: theme.spacing.xl * 3,
    },
  },
  hideOnDesktop: {
    display: 'block',
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  showOnDesktop: {
    display: 'block',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));

export const imageMaxWidth = { maxWidth: '100%', height: 'auto' };
