import { ComponentGlobalRichText } from 'types/cms';
import React from 'react';
import { Container } from '@mantine/core';

const RichText: React.FC<ComponentGlobalRichText> = ({
  content,
  anchorName,
}: ComponentGlobalRichText) => {
  return (
    <Container size="lg">
      <div id={anchorName!} dangerouslySetInnerHTML={{ __html: content! }} />
    </Container>
  );
};

export default RichText;
