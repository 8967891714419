import { PageBodyDynamicZone } from 'types/cms';
import HeroQuickDonate from './Hero/HeroQuickDonate';
import DonateQuery from './DonateBox/DonateQuery';
import SectionWithCards from './Blocks/SectionWithCards';
import FeaturedAppeals from './Blocks/FeaturedAppeals';
import FeaturedEvents from './Blocks/FeaturedEvents';
import SubscribeCta from '../modules/home/SubscribeCta';
import Hero from './Hero';
import Title from './Blocks/Title';
import SectionWithText from './Blocks/SectionWithText';
import RichText from './Blocks/RichText';
import NewsItems from './Blocks/NewsItems';
import EmbedContent from './Blocks/EmbedContent';
import YoutubeEmbed from './Blocks/YoutubeEmbed';
import HeroQuickDonateQuery from './Hero/HeroQuickDonateQuery';
import LeaderboardGroupContainer from './LeaderboardGroup/LeaderboardGroupContainer';
import WriteToGov from './Blocks/WriteToGov';
import ShopItems from './Blocks/ShopItems';
import SpaceGap from './Blocks/SpaceGap';
import BtnWithText from '@/components/Blocks/BtnWithText';
import ImageList from '@/components/Blocks/ImageList';

const BlockManager = ({ blocks, pageInfo }: any) => {
  return (
    <div>
      {blocks.map(({ __typename, ...rest }: PageBodyDynamicZone, index: number) => {
        let Block: any;

        switch (__typename) {
          case 'ComponentHeroHero':
            Block = Hero;
            break;
          case 'ComponentWrite2GovWriteToGov':
            Block = WriteToGov;
            break;
          case 'ComponentGlobalSpace':
            Block = SpaceGap;
            break;
          case 'ComponentShopItem':
            Block = ShopItems;
            break;
          case 'ComponentHeroHeroQuickDonateQuery':
            Block = HeroQuickDonateQuery;
            break;
          case 'ComponentGlobalDonationQuery':
            Block = DonateQuery;
            break;
          case 'ComponentGlobalSectionWithText':
            Block = SectionWithText;
            break;
          case 'ComponentGlobalButtonWithText':
            Block = BtnWithText;
            break;
          case 'ComponentGlobalTitle':
            Block = Title;
            break;
          case 'ComponentGlobalRichText':
            Block = RichText;
            break;
          case 'ComponentGlobalNewsTickerList':
            Block = NewsItems;
            break;
          case 'ComponentGlobalEmbedContent':
            Block = EmbedContent;
            break;
          case 'ComponentHeroHeroQuickDonate':
            Block = HeroQuickDonate;
            break;
          case 'ComponentGlobalSectionHeading':
            Block = SectionWithCards;
            break;
          case 'ComponentAppealsFeaturedAppeals':
            Block = FeaturedAppeals;
            break;
          case 'ComponentGlobalSubscribe':
            Block = SubscribeCta;
            break;
          case 'ComponentGlobalImages':
            Block = ImageList;
            break;
          case 'ComponentChallengesFeaturedEventsChallenges':
            Block = FeaturedEvents;
            break;
          case 'ComponentGlobalYouTube':
            Block = YoutubeEmbed;
            break;
          case 'ComponentDonationTeams':
            Block = LeaderboardGroupContainer;
            break;
        }

        return Block ? <Block pageInfo={pageInfo} key={`index-${index}`} {...rest} /> : null;
      })}
    </div>
  );
};

BlockManager.defaultProps = {
  blocks: [],
};

export default BlockManager;
