import { Stepper, StepperProps } from '@mantine/core';
export default function StyledStepper(props: StepperProps) {
  return (
    <Stepper
      styles={{
        stepBody: {
          display: 'none',
        },
        content: {
          margin: 0,
          padding: 0,
        },
        steps: {
          display: 'none',
        },
      }}
      {...props}
    />
  );
}
