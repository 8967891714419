import React from 'react';
import { Text, Title, Flex, Tooltip, Checkbox } from '@mantine/core';
import { CheckoutModalType } from './CheckoutModalType';
import { UseFormReturnType } from '@mantine/form';
import { BsInfoCircle } from 'react-icons/bs';

const CheckoutGuest = ({ form }: { form: UseFormReturnType<CheckoutModalType> }) => {
  return (
    <>
      <Flex gap="sm">
        <Title order={4}>Gift Aid Preferences</Title>
        <Tooltip
          multiline
          width={350}
          withArrow
          transition="fade"
          transitionDuration={200}
          events={{ hover: true, focus: true, touch: true }}
          label="Gift Aid is a tax relief scheme, allowing UK Charities to reclaim an extra 25% in tax on every eligible donation made by a UK taxpayer. If you are a UK taxpayer, why not consider making your donation go further, at no extra cost, by selecting Gift Aid. For every £1 you donate, we can claim a further 25 pence, at no extra cost to you."
        >
          <Text size="lg">
            <BsInfoCircle />
          </Text>
        </Tooltip>
      </Flex>

      <Text mb={15} fz="sm" fw={500}>
        If you are a UK taxpayer the value of your gift can increase by 25% at no extra cost to you!
      </Text>

      <Checkbox
        {...form.getInputProps('giftaid', { type: 'checkbox' })}
        label="I am a UK taxpayer, donating as an individual and would like Human Aid & Advocacy to claim Gift Aid on my donation"
      />
      <Title mt={20} order={4}>
        Receive Feedback?
      </Title>
      <Checkbox.Group
        {...form.getInputProps('keepInTouch')}
        label="Would you like to receive evidence of the donations life changing impact?"
      >
        <Checkbox value="email" label="Yes" />
      </Checkbox.Group>
    </>
  );
};

export default CheckoutGuest;
