import { ComponentGlobalMetadata, Maybe } from 'types/cms';
import { NextSeo } from 'next-seo';

type SeoType = {
  metadata?: Maybe<ComponentGlobalMetadata> | Partial<ComponentGlobalMetadata>;
};

const Seo = ({ metadata }: SeoType) => {
  // Prevent errors if no metadata was set
  if (!metadata) return null;
  let url = '';
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }
  return (
    <NextSeo
      title={metadata.metaTitle! || ''}
      description={metadata.description! || ''}
      openGraph={{
        locale: 'en_GB',
        url,
        // Title and description are mandatory
        title: metadata.metaTitle! || '',
        description: metadata.description! || '',
        // Only include OG image if we have it
        // Careful: if you disable image optimization in Strapi, this will break
        ...(metadata.shareImage &&
          metadata.shareImage?.data &&
          metadata?.shareImage?.data?.attributes?.formats && {
            images:
              Object.values(metadata?.shareImage?.data?.attributes?.formats).map((image: any) => {
                return {
                  url: image.url,
                  width: image.width,
                  height: image.height,
                };
              }) || '',
          }),
      }}
    />
  );
};

export default Seo;
