import React from 'react';
import { Flex, Group, Text, RingProgress, Title, useMantineTheme } from '@mantine/core';
const TotalDonations = ({
  amountRaised,
  targetAmount,
}: {
  amountRaised: number;
  targetAmount: number;
}) => {
  const theme = useMantineTheme();
  const percentage =
    amountRaised && targetAmount && Math.round((amountRaised / targetAmount) * 100);
  return (
    <Flex wrap="wrap" justify="space-around">
      <RingProgress
        size={250}
        sections={[{ value: percentage, color: 'yellow' }]}
        label={
          <>
            <Title
              order={6}
              size={'h3'}
              variant="gradient"
              gradient={{ from: theme.primaryColor, to: 'red', deg: 45 }}
              ta="center"
              fw={700}
            >
              Total{' '}
            </Title>
            <Title
              order={5}
              size={'h1'}
              variant="gradient"
              gradient={{ from: theme.primaryColor, to: 'red', deg: 45 }}
              ta="center"
              fw={700}
            >
              {percentage}%
            </Title>
          </>
        }
      />
      <Flex style={{ flexGrow: 1 }} gap="md" justify="center" align="stretch" direction="column">
        <Group py={10} grow>
          <Text align="left" fw={600} size="sm" tt="uppercase">
            Amount Raised
          </Text>
          <Text size="lg" align="right">
            £
            {amountRaised.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </Group>
        {/* <hr /> */}
        <Group align="center" py={10} grow>
          <Text align="left" fw={600} size="sm" tt="uppercase">
            Target
          </Text>
          <Text size="lg" align="right">
            £
            {targetAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </Group>
      </Flex>
    </Flex>
  );
};

export default TotalDonations;
