import React from 'react';
import { Container, createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  wrapper: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },

  frame: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}));

const YoutubeEmbed = ({ embedId }: { embedId: string }) => {
  const { classes } = useStyles();

  return (
    <Container my={20}>
      <div className={classes.wrapper}>
        <iframe
          className={classes.frame}
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </Container>
  );
};

export default YoutubeEmbed;
