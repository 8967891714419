import { Container, Title, Box, Text } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import { AppealEntity, ComponentAppealsFeaturedAppeals } from 'types/cms';
import Card from '../Cards';
import Carousel from '../Carousel';
import { homeStyles } from '../../modules/shared/styles';

const FeaturedAppeals: React.FC<ComponentAppealsFeaturedAppeals> = ({
  title,
  footerLinkHref,
  footerLinkText,
  appeals,
}: ComponentAppealsFeaturedAppeals) => {
  const carouselData =
    appeals &&
    appeals.data.length > 0 &&
    appeals?.data.map((appeal: AppealEntity) => {
      return {
        title: appeal.attributes?.title,
        description: appeal.attributes?.shortDescription,
        image: appeal.attributes?.image,
        btnText: 'Learn more',
        btnUrl: `/appeals/${appeal.attributes?.slug}`,
      };
    });
  const { classes } = homeStyles({});

  return (
    <Container className={classes.sectionBackground} size="lg" mt={10}>
      <Box my="3rem">
        <Title order={3} mb="1rem" className={classes.heading}>
          {title}
        </Title>

        {carouselData ? <Carousel data={carouselData} view={<Card />} /> : null}

        <Link passHref href={{ pathname: footerLinkHref }} className={classes.viewAll}>
          <Text align="center">{footerLinkText}</Text>
        </Link>
      </Box>
    </Container>
  );
};

export default FeaturedAppeals;
