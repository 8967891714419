import React, { useEffect, useState } from 'react';
import { Query, ComponentHeroHeroQuickDonateQuery } from 'types/cms';
import fetch from 'isomorphic-unfetch';
import { Box, Container, MediaQuery, Select } from '@mantine/core';
import DonateBox from '../DonateBox';
import Hero from '.';

const DonateQuery = ({
  DonationQuery: donationQuery,
  Hero: hero,
}: ComponentHeroHeroQuickDonateQuery) => {
  const { filters, query } = { ...donationQuery };
  const [data, setData] = useState<Query>();
  const [selectedAppeal, setSelectedAppeal] = useState<string>('');

  const foundAppealsDataIndex =
    data?.appeals?.data.findIndex((el) => el?.attributes?.title === selectedAppeal) || 0;

  useEffect(() => {
    const fetchData = () => {
      fetch('/api/run-query', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          filters,
        }),
      })
        .then((resp) => resp.json())
        .then((info) => {
          setData(info);
          if (info?.appeals?.data?.length > 0) {
            setSelectedAppeal(info.appeals.data[0]?.attributes?.title || '');
          }
        });
    };
    fetchData();
  }, [donationQuery]);

  const handleSelectChange = (value: string) => {
    if (value) {
      setSelectedAppeal(value);
    }
  };

  return (
    <>
      <Hero {...hero}>
        <Box sx={{ maxWidth: '380px' }}>
          {data?.appeals?.data && data.appeals?.data?.length > 1 && (
            <Select
              mb={10}
              placeholder="Select an appeal"
              data={
                data?.appeals?.data &&
                data.appeals.data.map((appeal) => ({
                  label: appeal.attributes?.title || '',
                  value: appeal.attributes?.title || '',
                }))
              }
              value={selectedAppeal}
              onChange={handleSelectChange}
            />
          )}
          {data?.appeals?.data && data?.appeals?.data?.length > 0 && (
            <DonateBox
              key={data?.appeals?.data[foundAppealsDataIndex].id}
              pageInfo={data?.appeals?.data[foundAppealsDataIndex].attributes}
              appealId={data?.appeals?.data[foundAppealsDataIndex].id!}
              // @ts-ignore
              donateOptions={data?.appeals?.data[foundAppealsDataIndex].attributes?.donateOptions}
            />
          )}
        </Box>
      </Hero>
      <Container size="lg" mt="1.5rem">
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Box>
            {data?.appeals?.data && data.appeals?.data?.length > 1 && (
              <Select
                mb={10}
                // px={2}
                placeholder="Select an appeal"
                data={
                  data?.appeals?.data &&
                  data.appeals.data.map((appeal) => ({
                    label: appeal.attributes?.title || '',
                    value: appeal.attributes?.title || '',
                  }))
                }
                value={selectedAppeal}
                onChange={handleSelectChange}
              />
            )}
            {data?.appeals?.data && data?.appeals?.data?.length > 0 && (
              <DonateBox
                key={data?.appeals?.data[foundAppealsDataIndex].id}
                pageInfo={data?.appeals?.data[foundAppealsDataIndex].attributes}
                // @ts-ignore
                donateOptions={data?.appeals?.data[foundAppealsDataIndex].attributes?.donateOptions}
              />
            )}
          </Box>
        </MediaQuery>
      </Container>
    </>
  );
};

export default DonateQuery;
