import { ComponentShopItem, ComponentShopOptions, Maybe } from 'types/cms';
import React, { useState } from 'react';
import { Container, Text, Select, Chip, Paper } from '@mantine/core';
import ItemCard from '../Cards/ShopItemCard';

type IPropertyType = {
  colour: string;
  size: string;
  pocket: boolean;
};

type ShopOptions = {
  colour?: 'String';
  size?: 'String';
  pocket?: 'Boolean';
  url?: 'String';
  price?: 'Float';
};

function DropdownList({
  setSelectedOption,
  options,
  defaultVal,
}: {
  setSelectedOption: React.Dispatch<React.SetStateAction<string | null>>;
  options: any;
  defaultVal?: string;
}) {
  const handleChange = (value: string) => {
    setSelectedOption(value);
  };
  return (
    <Chip.Group value={defaultVal} onChange={handleChange}>
      {options.map((value: string) => (
        <Chip key={value} value={value}>
          {value}
        </Chip>
      ))}
    </Chip.Group>
  );
}

const ShopItems: React.FC<ComponentShopItem> = ({
  itemBtnText,
  id,
  itemImage,
  title,
  description,
  option,
}: ComponentShopItem) => {
  const colours = buildOption(option as ShopOptions[], 'colour');
  const sizes = buildOption(option as ShopOptions[], 'size');
  const pockets = buildOption(option as ShopOptions[], 'pocket');
  const [colour, colourSet] = useState<any>(colours[0]);
  const [size, sizeSet] = useState<any>(sizes[0]);
  const [pocket, pocketSet] = useState<any>(pockets[0] || null);

  const getFirstSetOfUrls = option
    ?.filter((x: Maybe<ComponentShopOptions>) => x?.colour === colour)
    .filter((x: Maybe<ComponentShopOptions>) => x?.size === size);
  const getPocketOptions =
    pocket === null
      ? getFirstSetOfUrls
      : getFirstSetOfUrls?.filter((x: any) => {
          const convert = pocket === 'Yes' ? true : false;
          return x.pocket === convert;
        });

  // const getUrlFromOptions = getPocketOptions?.map((x: Maybe<ComponentShopOptions>) => x?.url);

  return (
    <Container size="xs">
      <div>
        <ItemCard
          // might need to dynamically get the btnText from the chosen variations
          buttonText={
            getPocketOptions?.[0]?.price
              ? `£${String(getPocketOptions?.[0]?.price)}`
              : 'Out of stock'!
          }
          image={itemImage}
          slug={getPocketOptions?.[0]?.url || ''}
          textSize={'xl'}
          title={title!}
          disabled={!getPocketOptions?.[0]?.price}
        >
          {description}
          <Paper pt="md" pb="md">
            <Text size="sm">Colour</Text>
            <DropdownList setSelectedOption={colourSet} options={colours} defaultVal={colour} />
            <Text size="sm" pt="xs">
              Size
            </Text>
            <DropdownList setSelectedOption={sizeSet} options={sizes} defaultVal={size} />

            {pockets.length > 1 && (
              <>
                <Text size="sm" pt="xs">
                  Pocket
                </Text>
                <DropdownList setSelectedOption={pocketSet} options={pockets} defaultVal={pocket} />
              </>
            )}
          </Paper>

          {/* {JSON.stringify(option)} */}
        </ItemCard>
      </div>
    </Container>
  );
};

export default ShopItems;

const buildOption = (options: ShopOptions[], property: string) => {
  if (typeof options[0][property as unknown as keyof IPropertyType] === 'boolean') {
    const uniqueValues = options
      ?.filter((option) => option![property as unknown as keyof IPropertyType] !== null)
      .map((option) => (option[property as unknown as keyof IPropertyType] ? 'Yes' : 'No'));
    return [...new Set(uniqueValues)];
  } else {
    const uniqueValues = options?.map(
      (option) => option[property as unknown as keyof IPropertyType]
    );
    return [...new Set(uniqueValues)];
  }
};
