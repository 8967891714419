import React, { use, useEffect, useState } from 'react';
import {
  Container,
  Text,
  Grid,
  Stack,
  Textarea,
  Title,
  Radio,
  Progress,
  Paper,
} from '@mantine/core';
import { ComponentWrite2GovWriteToGov } from 'types/cms';
import { Button, TextInput, Group, Loader } from '@mantine/core';
import { TransformedValues, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { WriteToGovForm } from 'types/otherTypes';

export default function WriteToGov({ write_to_gov }: ComponentWrite2GovWriteToGov) {
  if (!write_to_gov?.data?.id) {
    return null;
  }
  const [emailsSent, setEmailsSent] = useState(0);
  // const [currentTarget, setCurrentTarget] = useState(500);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [step2, setStep2] = useState(false);
  const addressTo = write_to_gov.data.attributes?.emails?.map((email) => email?.name).join(', ');
  // set current target to the nearest 1000 from emailsSent
  const currentTarget = Math.ceil((emailsSent + 1000) / 1000) * 1000;
  const form = useForm<WriteToGovForm>({
    initialValues: {
      entryId: write_to_gov?.data?.id || '',
      firstName: '',
      lastName: '',
      postCode: '',
      phone: '',
      email: '',
      addressLine1: '',
      subject: write_to_gov.data.attributes?.subject || '',
      body: write_to_gov.data.attributes?.body || '',
      contactByEmail: 'yes',
    },
    validate: {
      email: (value: string) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email'),
      firstName: (value: string) => (value.trim().length > 2 ? null : 'First name is required'),
      lastName: (value: string) => (value.trim().length > 2 ? null : 'Last name is required'),
      postCode: (value: string) => (value.trim().length > 1 ? null : 'Post code is required'),
    },
  });

  useEffect(() => {
    const fetchEmailsSent = async () => {
      const response = await fetch('/api/get-write-to-gov-emails-sent', {
        method: 'POST',
        body: JSON.stringify({ id: write_to_gov?.data?.id }),
      });
      const data = await response.json();
      setEmailsSent(data);
    };
    if (write_to_gov.data?.attributes?.showProgressBar) {
      fetchEmailsSent();
    }
  }, [write_to_gov]);
  const ProgressComponent = () => {
    if (write_to_gov.data?.attributes?.showProgressBar) {
      return (
        <>
          <Progress
            color="green"
            style={{ height: 20 }}
            value={(emailsSent / currentTarget) * 100}
          />
          <Text>
            <strong>{emailsSent}</strong> emails have been sent. Will you help us reach{' '}
            <strong>{currentTarget}</strong>?
          </Text>
        </>
      );
    }
    return null;
  };
  const handleSubmit = async (values: TransformedValues<typeof form>) => {
    setLoading(true);
    try {
      const response = await fetch('/api/send-write-to-gov', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values }),
      });
      const data: { status: string; message: string } = await response.json();

      if (!response.ok) {
        throw data;
      }
      if (data.status === 'ok') {
        setLoading(false);
        setComplete(true);
        showNotification({
          message: 'Email(s) sent',
        });
      } else {
        setLoading(false);
        throw data.message || `Please check your email and try again`;
      }
    } catch (error: any) {
      showNotification({
        message: error,
      });
      setLoading(false);
      console.error(error);
    }
  };
  const goToStep2 = () => {
    if (!form.isValid()) {
      form.validate();
      return;
    }
    form.setFieldValue(
      'body',
      `${form.values.body}

${form.values.firstName} ${form.values.lastName}
${form.values.addressLine1}
${form.values.postCode}
${form.values.phone} ${form.values.email}

I sent this email via the Human Aid & Advocacy website. Replies will go to my personal email address.`
    );
    setStep2(true);
  };
  return (
    <Container size="xs" my="xs">
      <Paper shadow="xs" p="md">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {complete && <Title order={2}>Thank you for your submission</Title>}
          {!complete && step2 && (
            <Stack spacing="md">
              <Text fz="xs" fw={400}>
                <strong>
                  We've written a template email that you can send, but why not add a personal touch
                  to give it more impact?
                </strong>{' '}
                Adding a sentence or two at the beginning and end is a great place to start.
              </Text>
              <TextInput label="Subject" {...form.getInputProps('subject')} withAsterisk />
              <Text fz={'sm'}>Dear {addressTo}</Text>
              <Textarea
                {...form.getInputProps('body')}
                label="Message"
                autosize
                minRows={2}
                withAsterisk
              />
              {loading && <Loader />}
              <Button disabled={loading} type="submit">
                Send
              </Button>
            </Stack>
          )}
          {!complete && !step2 && (
            <Stack spacing="sm">
              <ProgressComponent />
              <Text fz="sm">
                Enter your details. You'll be able to edit a template email on the next step.
              </Text>
              <Group>
                <TextInput
                  required
                  withAsterisk
                  label="First Name"
                  {...form.getInputProps('firstName')}
                />
                <TextInput
                  required
                  withAsterisk
                  label="Last Name"
                  {...form.getInputProps('lastName')}
                />
                <TextInput required withAsterisk label="Email" {...form.getInputProps('email')} />
                <Radio.Group
                  name="contactPreferences"
                  label="Update me on this and future campaigns"
                  spacing="xs"
                  size="sm"
                  {...form.getInputProps('contactByEmail')}
                >
                  <Radio value="no" label="No" />
                  <Radio value="yes" label="Yes" />
                </Radio.Group>
                <TextInput
                  required
                  withAsterisk
                  label="First line of address"
                  {...form.getInputProps('addressLine1')}
                />
                <TextInput
                  required
                  withAsterisk
                  label="Post Code"
                  {...form.getInputProps('postCode')}
                />
                <TextInput label="Phone" {...form.getInputProps('phone')} />
              </Group>
              <Button onClick={goToStep2}>Next</Button>
            </Stack>
          )}
        </form>
      </Paper>
    </Container>
  );
}
