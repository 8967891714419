import { ComponentGlobalTitle } from 'types/cms';
import { Container, Title as Heading, Box } from '@mantine/core';
import React from 'react';
import { homeStyles } from '../../modules/shared/styles';

const Title: React.FC<ComponentGlobalTitle> = ({ title, hideOnMobile }: ComponentGlobalTitle) => {
  const { classes } = homeStyles({});

  return (
    <Container>
      <Box my="lg" ta="center" fw={600} tt="uppercase">
        {/* <Heading > */}
        {title && <div dangerouslySetInnerHTML={{ __html: title }} />}
        {/* </Heading> */}
      </Box>
    </Container>
  );
};

export default Title;
