import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { Card, Image, Text, Button, Group, Box, MantineNumberSize } from '@mantine/core';
import Link from 'next/link';

import React from 'react';
// import { BsShare } from 'react-icons/bs';
import { Maybe, UploadFileEntityResponse } from 'types/cms';

type CardBodyProps = Partial<{
  title: string;
  children: React.ReactNode;
  slug?: string;
  image?: Maybe<UploadFileEntityResponse> | undefined;
  buttonText?: string;
  textSize: MantineNumberSize;
  disabled: boolean;
}>;

const ItemCard = ({
  textSize = 'md',
  image,
  title,
  children,
  slug,
  buttonText,
  disabled = false,
}: CardBodyProps) => {
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      {image?.data?.attributes?.formats?.thumbnail?.url ? (
        <Image
          src={getStrapiImage(image?.data?.attributes!, ImageSizeNameEnum.small)?.url}
          height={360}
          alt={title}
          fit="contain"
        />
      ) : (
        <Box sx={{ height: '160px' }}>&nbsp;</Box>
      )}

      <Text size={textSize} mt={10}>
        {title}
      </Text>
      {children}

      <Group position="apart" mt="md" mb="xs">
        <Link href={String(slug)} passHref legacyBehavior>
          <Button disabled={disabled} component="a" fullWidth color="yellow">
            <Text color="gray.7">{!buttonText ? 'See details' : buttonText}</Text>
          </Button>
        </Link>
      </Group>
    </Card>
  );
};

export default ItemCard;
