import { ComponentGlobalButtonWithText } from 'types/cms';
import {
  Container,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Center,
  Flex,
  Group,
  Stack,
  MediaQuery,
} from '@mantine/core';
import React, { Fragment } from 'react';
import { homeStyles } from '../../modules/shared/styles';
import Link from 'next/link';

const BtnWithText = (item: ComponentGlobalButtonWithText) => {
  const { classes } = homeStyles({});
  const convertColourToThemeColour = (colour?: string | null): string => {
    if (!colour) return '';
    if (colour === 'red') return 'red.9';
    if (colour === 'black') return 'black.9';
    return colour;
  };

  return (
    <Container className={classes.sectionSpacing}>
      <Grid>
        <Grid.Col md={4} orderMd={item?.btnOnRight ? 2 : 1}>
          <Link passHref legacyBehavior href={{ pathname: item?.btnUrl }}>
            <Button
              component="a"
              color={convertColourToThemeColour(item?.btnColour)}
              size="xl"
              mt="xl"
              radius="md"
              fullWidth
            >
              <Text
                c={item.btnColour !== 'yellow' && item.btnColour !== null ? 'white' : 'black.9'}
                size="xl"
              >
                {item?.btnText}
              </Text>
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col md={8} orderMd={item?.btnOnRight ? 1 : 2}>
          <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
            <div dangerouslySetInnerHTML={{ __html: item?.text || '' }} />
          </MediaQuery>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default BtnWithText;
