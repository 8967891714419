import { Carousel as Slider } from '@mantine/carousel';
import React from 'react';
import { useMantineTheme } from '@mantine/core';

type CarouselProps<SlideData> = {
  data: SlideData[] | any;
  view: React.ReactElement<SlideData>;
};

const Carousel = <SlideData extends Record<string, unknown>>({
  data,
  view,
}: CarouselProps<SlideData>) => {
  const theme = useMantineTheme();
  return (
    <Slider
      height="100%"
      slideSize="33%"
      slideGap="md"
      breakpoints={[
        { maxWidth: 'md', slideSize: '50%' },
        { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
      ]}
      align="start"
      slidesToScroll={1}
      loop
      styles={{
        control: {
          padding: 0,
          marginLeft: -20,
          marginRight: -20,
          opacity: 0.5,
          color: '#ffffff',
          backgroundColor: theme.colors.yellow[7],
        },
      }}
    >
      {data &&
        data.length > 0 &&
        data.map((slide: any, i: number) => (
          <Slider.Slide key={i}>{React.cloneElement(view, { ...slide })}</Slider.Slide>
        ))}
    </Slider>
  );
};

export default Carousel;
