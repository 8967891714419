import React from 'react';
import { Card, Image, Text, Button, Group } from '@mantine/core';
import Link from 'next/link';
import { ComponentGlobalFundraisingOption } from 'types/cms';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';

interface cardProps extends Omit<ComponentGlobalFundraisingOption, 'id'> {
  showBorder?: boolean;
}

const Cards = ({
  title,
  description,
  image,
  btnText,
  btnUrl,
  showBorder = true,
  showBtn = true,
}: cardProps) => (
  <Card shadow={showBorder ? 'sm' : undefined} p="lg" radius="md" withBorder={showBorder}>
    <Image
      src={getStrapiImage(image?.data?.attributes!, ImageSizeNameEnum.small)?.url}
      height={160}
      alt={title!}
      fit="contain"
    />

    <Text size="md" mt={10}>
      {title}
    </Text>

    <Text size="sm" color="dimmed">
      {description}
    </Text>

    <Group position="apart" mt="md" mb="xs">
      {showBtn && (
        <Link passHref legacyBehavior href={{ pathname: btnUrl }}>
          <Button component="a" color="yellow" mt="md" radius="md" fullWidth>
            {btnText}
          </Button>
        </Link>
      )}
      {/* {showShareBtn && (
        <Box mt="md">
          <BsShare />
        </Box>
      )} */}
    </Group>
  </Card>
);

export default Cards;
