import React from 'react';
import { Badge, Tabs } from '@mantine/core';
import {
  FundraisePageEntity,
  FundraiseTeamCampaign,
  FundraiseTeamCampaignEntity,
  Maybe,
} from 'types/cms';
import { useStyles } from '@/modules/fundraiseParent/TopFundraisers/styles';
import TeamAmountRaised from '@/components/AmountRaised/FundraiserTeam';
interface LeaderboardGroupProps {
  sortedTeams: FundraiseTeamCampaignEntity[];
  sortedMemberPages: FundraisePageEntity[];
  calculateTeamTotalDonations: (team: Maybe<FundraiseTeamCampaignEntity>) => number;
  calculateMemberTotalDonations: (memberPage: FundraisePageEntity) => number;
}

const LeaderboardGroup: React.FC<LeaderboardGroupProps> = ({
  sortedTeams,
  sortedMemberPages,
  calculateTeamTotalDonations,
  calculateMemberTotalDonations,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Tabs color="yellow" defaultValue="team" mt="2rem">
        <Tabs.List mb="lg">
          <Tabs.Tab value="individual" className={classes.tab_button}>
            Individual Fundraisers
          </Tabs.Tab>
          <Tabs.Tab value="team" className={classes.tab_button}>
            Team Fundraisers
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="team" pt="xs">
          <Badge color="red" variant="outline" mb={20}>
            Since start
          </Badge>
          {sortedTeams?.map((team, ind) => (
            <TeamAmountRaised
              key={team.id}
              slug={`/f/${team.attributes?.campaign_page?.data?.attributes?.slug}`}
              rank={ind + 1}
              targetAmount={team.attributes?.campaign_page?.data?.attributes?.goal || 0}
              amountRaised={calculateTeamTotalDonations(team)}
              teamName={team.attributes?.campaign_page?.data?.attributes?.name || ''}
              name={team.attributes?.campaign_page?.data?.attributes?.fullName || ''}
            />
          ))}
        </Tabs.Panel>

        <Tabs.Panel value="individual" pt="xs">
          <Badge color="red" variant="outline" mb={20}>
            Since start
          </Badge>
          {sortedMemberPages.map((memberPage, ind) => (
            <TeamAmountRaised
              key={memberPage.id}
              slug={`/f/${memberPage?.attributes?.slug}`}
              rank={ind + 1}
              targetAmount={memberPage?.attributes?.goal || 0}
              amountRaised={calculateMemberTotalDonations(memberPage)}
              teamName={memberPage?.attributes?.name || ''}
              name={`${memberPage.attributes?.fullName} - ${(memberPage as any).teamName} `}
            />
          ))}
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default LeaderboardGroup;
