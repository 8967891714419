import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tab_button: {
    fontSize: '0.75rem',
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: '1.25rem',
    },
  },
}));
