import { ComponentGlobalNewsTickerList, NewsTickerEntity } from 'types/cms';
import React, { useState } from 'react';
import { Alert, Box } from '@mantine/core';

const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(' ');
};

const NewsItems: React.FC<ComponentGlobalNewsTickerList> = ({
  news_ticker_items,
}: ComponentGlobalNewsTickerList) => {
  const [isPaused, setIsPaused] = useState(false);

  const newsItems = news_ticker_items?.data;
  if (!newsItems?.length) return null;
  const NewsItem = ({ item }: { item: NewsTickerEntity }) => (
    <Box
      px="md"
      fw={500}
      style={{
        whiteSpace: 'nowrap',
        flexShrink: 0,
      }}
    >
      <Box display={'inline-block'} fw={800} c="yellow.6">
        {item.attributes?.prefix}
      </Box>{' '}
      {item.attributes?.content}
    </Box>
  );
  return (
    <div
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
      role="region"
      aria-label="News updates"
      className="w-full overflow-hidden"
    >
      <Alert variant="filled" color="red.9" radius={0}>
        <div className="w-full overflow-hidden">
          <div
            className={classNames(
              'flex animate-scroll whitespace-nowrap',
              isPaused && 'animate-pause'
            )}
            style={{
              minWidth: 'max-content',
              display: 'inline-flex',
            }}
          >
            {newsItems?.map((item) => (
              <NewsItem key={`${item.id}`} item={item} />
            ))}
            {/* Duplicate items for seamless loop */}
            {newsItems?.map((item) => (
              <NewsItem key={`${item.id}-dup`} item={item} />
            ))}
          </div>
        </div>
      </Alert>

      <style jsx global>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-scroll {
          animation: scroll 23s linear infinite;
        }

        .animate-pause {
          animation-play-state: paused;
        }
      `}</style>
    </div>
  );
};

export default NewsItems;
