import React, { useState } from 'react';
import { TextInput } from '@mantine/core';
import { CheckoutModalType } from './CheckoutModalType';
import { UseFormReturnType } from '@mantine/form';

const CheckoutGuest = ({ form }: { form: UseFormReturnType<CheckoutModalType> }) => {
  return (
    <>
      <TextInput
        label="Email"
        placeholder="you@gmail.com"
        required
        {...form.getInputProps('email')}
      />
    </>
  );
};

export default CheckoutGuest;
