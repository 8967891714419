import { ImageLoader } from 'next/image';

const customLoader: ImageLoader = ({ src, width }) => {
  // Extract the image format and extension from the Cloudinary URL
  const formatMatch = src.match(/\/v\d+\/([^/]+)\.([^/]+)/);
  // debugger;
  const filename = formatMatch ? formatMatch[1] : '';
  const extension = formatMatch ? formatMatch[2] : 'jpg';

  // Determine the format based on the width
  let formatKey = '';
  if (width && width <= 245) formatKey = 'thumbnail_';
  else if (width && width <= 576) formatKey = 'xsmall_';
  else if (width && width <= 768) formatKey = 'small_';
  else if (width && width <= 992) formatKey = 'medium_';
  else if (width && width <= 1200) formatKey = 'large_';

  // Extract the base URL without transformations
  const baseUrlMatch = src.match(/(.+\/v\d+\/)[^/]+/);
  const baseUrl = baseUrlMatch ? baseUrlMatch[1] : '';

  // Get the corresponding URL from the formats attribute
  const imageUrl = `${baseUrl}${formatKey}${filename}.${extension}`;
  return imageUrl;
  // // Add quality parameter if specified
  // const qualityParam = quality ? `?q=${quality}` : '';

  // // Return the final URL
  // return `${imageUrl}${qualityParam}`;
};

export default customLoader;
