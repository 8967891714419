import { dateFutureByMins, formatToCurrency } from '@/utils/helpers';
import { Anchor, Badge, Grid, Text } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import ProgressBar from './ProgressBar';
import { format, parseISO } from 'date-fns';
import { GiReceiveMoney } from 'react-icons/gi';

type FundraiserTeamProps = {
  rank?: number;
  teamName?: string;
  name?: string;
  targetAmount: number | undefined;
  amountRaised: number | undefined;
  slug?: string;
  endDate?: string;
  showEnded?: boolean;
};

const FundraiserTeam = ({
  rank,
  teamName,
  name,
  targetAmount,
  amountRaised,
  slug,
  endDate,
  showEnded,
}: FundraiserTeamProps) => {
  const pageEnded = dateFutureByMins(endDate || '');

  return (
    <Grid align="center">
      {rank && (
        <Grid.Col span={1}>
          <Text fz={20}>{rank}</Text>
        </Grid.Col>
      )}
      <Grid.Col span="auto">
        <Text weight="bold" size="xl" mb="xs">
          {slug ? (
            <Link href={slug} passHref legacyBehavior>
              <Anchor color="yellow.8">{teamName}</Anchor>
            </Link>
          ) : (
            teamName
          )}
          <Text component="span" ml={10}>
            <GiReceiveMoney />
          </Text>
          <Text component="span" weight="normal" size="md">
            {' '}
            {formatToCurrency(amountRaised || '0')}
          </Text>
          {pageEnded && endDate && (
            <Badge size="xs" ml={5} color="red.8">
              Ended {format(parseISO(endDate!), 'dd MMM yy')}
            </Badge>
          )}
          {!pageEnded && endDate && (
            <Badge size="xs" ml={5} variant="dot" color="yellow.8">
              Ending {format(parseISO(endDate!), 'dd MMM yy')}
            </Badge>
          )}
        </Text>
        <Text size="sm">{name}</Text>

        <ProgressBar targetAmount={targetAmount} amountRaised={amountRaised} />
      </Grid.Col>
    </Grid>
  );
};

export default FundraiserTeam;
