import { Box, Container, MediaQuery } from '@mantine/core';
import React from 'react';
import Hero from '.';
import { Appeal, ComponentDonationQuickDonateOptions, ComponentHeroHero, Page } from 'types/cms';
import DonateBox from '../DonateBox';

type HeroQuickDonateProps = Partial<{
  hero: ComponentHeroHero;
  pageInfo: Page | Appeal;
  donateOptions: ComponentDonationQuickDonateOptions[];
}>;

// sfUpsellProjectId <-- to put this in state so we use at the end on thank you page
const HeroQuickDonate = ({ pageInfo, donateOptions, ...rest }: HeroQuickDonateProps) => {
  const { hero } = rest;
  return (
    <>
      <Hero {...hero}>
        <Box sx={{ maxWidth: '380px' }}>
          {donateOptions && (
            <DonateBox pageInfo={pageInfo} donateOptions={donateOptions} {...rest} />
          )}
        </Box>
      </Hero>
      <Container size="lg" mt="1.5rem">
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Box>
            {donateOptions && (
              <DonateBox pageInfo={pageInfo} donateOptions={donateOptions} {...rest} />
            )}
          </Box>
        </MediaQuery>
      </Container>
    </>
  );
};

export default HeroQuickDonate;
