import { Center, createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  otherAmount: {
    backgroundColor: '#e9ecef',
    borderRadius: '4px',
    padding: '4px',
    input: {
      color: 'black',
      textAlign: 'center',
    },
    'input::placeholder': {
      textAlign: 'center',
      color: theme.colors.gray,
    },
    'input:focus::placeholder': {
      color: 'transparent',
    },
  },
}));
