import { formatToCurrency } from '@/utils/helpers';
import { Group, Progress, Text } from '@mantine/core';
import React from 'react';

type ProgressBarProps = {
  targetAmount: number | undefined;
  amountRaised: number | undefined;
};

const ProgressBar = ({ targetAmount, amountRaised }: ProgressBarProps) => {
  const percentage =
    amountRaised && targetAmount && Math.round((amountRaised / targetAmount) * 100);
  return (
    <>
      <Progress color="yellow" value={percentage} mb={5} />

      <Group position="apart" mb="1rem">
        <Text size="xs">{percentage}% complete</Text>
        <Text size="xs">of {formatToCurrency(targetAmount!)}</Text>
      </Group>
    </>
  );
};

export default ProgressBar;
